import React, { createContext, ReactNode, useRef, useState } from "react";
import { HouseSelection } from "../components/frontOffice/clientForm/houseSelection";
import { Summary } from "../components/frontOffice/clientForm/summary";
import Guests from "../components/frontOffice/clientForm/guests";
import { eachDayOfInterval, format, subDays, transpose } from "date-fns";
import { HolidaysDuration } from "../components/frontOffice/clientForm/duration";
import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import ContactInfo from "../components/frontOffice/clientForm/contactInfo";
import { NoSSR } from "../components/no-ssr";
import { useComponentSize } from "src/utils/use-size";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import router, { useRouter } from "next/router";
import { parseDate } from "src/utils/parse-date";
import clsx from "clsx";
import { Accordion } from "src/components/accordion";
import { PageLayout } from "src/components/frontOffice/layout";
import Image from "next/image";
import { Category } from "src/utils/mews.schema";
import z from "zod";
import { useConfiguration } from "src/hooks/useMews";
import { useMutation } from "@tanstack/react-query";
import { UTCDate } from "@date-fns/utc";
import { parse } from "duration-fns";
// import { DevTool } from "@hookform/devtools";

type FormProps = {
  category_id: string;
  service_id: string;
  bookingDates: { startDate: string; endDate: string };
  guests: { adult: number; child: number; infant: number };
  contactInfo: {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    notes: string;
  };
};

export const social = [
  {
    name: "facebook",
    href: "https://www.facebook.com/IconicHouse-104529948520072",
    icon: "/pictos/fb.svg",
  },
  {
    name: "instagram",
    href: "https://www.instagram.com/iconic__house/",
    icon: "/pictos/insta.svg",
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/company/iconic-house",
    icon: "/pictos/linkedin.svg",
  },
];

export const StepContext = createContext<{
  nextStep?: () => void;
  to?: (e) => void;
  size?: { width: number; height: number };
}>({});

type StepHousesPlaceholder = "Houses" | "Booking dates" | "Guests" | "Contact details";

const STEPS: {
  placeholder: StepHousesPlaceholder;
  title: (values: any, house: z.infer<typeof Category>) => string | undefined;
  icon?: (house: z.infer<typeof Category>) => string | undefined;
  isDisabled: (values: any) => boolean;
  isComplete: (values: any) => boolean;
  component: ReactNode;
}[] = [
  {
    placeholder: "Houses",
    title: (_, house) => house?.Name,
    icon: () => null,
    isDisabled: () => false,
    isComplete: (values) => !!values.category_id,
    component: <HouseSelection />,
  },
  {
    placeholder: "Booking dates",
    title: (values) =>
      values.bookingDates.endDate &&
      `${format(parseDate(values.bookingDates.startDate), "dd/MM/yyyy")} - ${format(parseDate(values.bookingDates.endDate), "dd/MM/yyyy")}`,
    isDisabled: (values) => values.category_id === null,
    isComplete: (values) => !!values.bookingDates.endDate,
    component: <HolidaysDuration />,
  },
  {
    placeholder: "Guests",
    title: (values: Record<string, number>) => {
      const guestsTotal = Object.values(values.guests).reduce((acc, curr) => acc + curr, 0);
      return guestsTotal > 0 && `There will be ${guestsTotal} guests`;
    },
    isDisabled: (values) => values.bookingDates.endDate === null,
    isComplete: (values: Record<string, number>) => {
      const guestsTotal = Object.values(values.guests).reduce((acc, curr) => acc + curr, 0);
      return guestsTotal > 0;
    },
    component: <Guests />,
  },
  {
    placeholder: "Contact details",
    title: (values) =>
      values.contactInfo?.first_name &&
      values.contactInfo?.last_name &&
      values.contactInfo?.email &&
      values.contactInfo?.phone &&
      `${values.contactInfo.first_name} ${values.contactInfo.last_name}`,
    isDisabled: (values) => values.category_id === null || values.bookingDates.endDate === null,
    isComplete: (values) =>
      values.contactInfo?.first_name && values.contactInfo?.last_name && values.contactInfo?.email && values.contactInfo?.phone,
    component: <ContactInfo />,
  },
];

const Form: React.FC = () => {
  const methods = useFormContext<FormProps>();
  const category_id = methods.getValues("category_id");
  const service_id = methods.getValues("service_id");
  const successMessageRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(category_id ? 1 : 0);
  const [done, setDone] = useState(false);
  const refs = useRef<React.RefObject<HTMLButtonElement>[]>(Array.from({ length: STEPS.length }, () => React.createRef()));
  const clientFormRef = useRef(null);

  const size = useComponentSize(clientFormRef);

  const values = methods.watch();

  const nextStep = () => {
    refs.current[currentStep].current.scrollIntoView();
    setCurrentStep(currentStep + 1);
  };

  const validatedUrl = () => {
    const { pathname, query } = router;
    const updatedQuery = {
      ...query,
      ...{ submitted: "true" },
    };
    router.replace({
      pathname,
      query: updatedQuery,
    });
  };

  const to = (step) => {
    // All these positions are relative to the viewport
    // GTK: Refs point to the header of each step!!!!!
    const topOfNewStep = refs.current[step].current.getBoundingClientRect().top;
    const topOfCurrentStep = refs.current[currentStep].current.getBoundingClientRect().top;
    const bottomOfCurrentStep = refs.current[currentStep + 1].current.getBoundingClientRect().top;
    const heightOfActualStepOpen = bottomOfCurrentStep - topOfCurrentStep;
    const distanceToGoTo = topOfNewStep - heightOfActualStepOpen;
    scrollBy(0, distanceToGoTo);
    setCurrentStep(step);
  };

  const { getCurrentCategory, getCategoryImages, getServiceByServiceId } = useConfiguration();

  const currService = getServiceByServiceId(values.service_id);

  const bookingShakeSpacesId = JSON.parse(process.env.NEXT_PUBLIC_BOOKING_SHAKE_SPACES_ID);

  const submitMutation = useMutation({
    mutationFn: async (data: FormProps) => {
      const bookingStartTime = parse(currService.Data.Value?.StartOffset);
      const bookingEndTime = parse(currService.Data.Value?.EndOffset);

      const eachDayOfBookingInterval = eachDayOfInterval({
        start: transpose(new Date(data.bookingDates.startDate), UTCDate),
        end: transpose(subDays(new Date(data.bookingDates.endDate), 1), UTCDate), // We count the number of nights instead of the number of days by subtracting one day from the end date
      });

      const formattedBookingStartTime = `${bookingStartTime.hours}:${bookingStartTime.minutes.toString().padStart(2, "0")}`;
      const formattedBookingEndTime = `${bookingEndTime.hours}:${bookingEndTime.minutes.toString().padStart(2, "0")}`;

      const formattedBookings = eachDayOfBookingInterval.map((date) => {
        const formattedDate = format(date, "dd-MM-yyyy");
        return {
          date: formattedDate,
          startTime: formattedBookingStartTime,
          endTime: formattedBookingEndTime,
          pax: `Adults: ${data.guests.adult}, Children: ${data.guests.child}, Infants: ${data.guests.infant}`,
          space_id: bookingShakeSpacesId[category_id],
          comments: data.contactInfo.notes,
        };
      });

      const formattedContact = {
        firstname: data.contactInfo.first_name,
        lastname: data.contactInfo.last_name,
        email: data.contactInfo.email,
        phone_number: data.contactInfo.phone,
      };

      const bookingRequest = {
        bookings: formattedBookings,
        contact: formattedContact,
      };

      return await fetch("/api/booking-shake/reservation", {
        method: "POST",
        body: JSON.stringify(bookingRequest),
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Request error");
        }
      });
    },
    onSuccess: () => {
      setDone(true);
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      validatedUrl();
    },
  });

  const category = getCurrentCategory(category_id);

  const totalGuests = Object.values<number>(values.guests).reduce((acc, curr) => acc + curr, 0);

  return (
    <StepContext.Provider value={{ to, size, nextStep }}>
      <form onSubmit={methods.handleSubmit((data) => submitMutation.mutate(data))}>
        {/* <DevTool control={methods.control} /> */}
        <div className="pb-32 md:flex md:justify-center">
          <div
            className="mx-auto flex w-11/12 flex-col gap-x-10 gap-y-8 md:mx-0 md:grid md:grid-cols-formcomputer md:grid-rows-formComputer computer:w-max computer:gap-x-16 computer:gap-y-6"
            ref={successMessageRef}
          >
            {done ? (
              <div className="flex h-full items-center justify-center border-b border-t border-black">
                <div className="my-10 flex flex-col space-y-4 text-center">
                  <p className="font-nantes text-4xl">
                    Thank <span className="italic">you !</span>
                  </p>
                  <p className="mx-auto w-3/5 font-adrianna font-bold">
                    A dedicated team member will contact you shortly to prepare your stay.
                  </p>
                  <h3 className="font-adrianna-extended text-sm font-bold uppercase tracking-widest text-black">Follow us</h3>
                  <div className="mx-auto flex space-x-3">
                    {social.map((item) => (
                      <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className="text-black hover:text-gray-700 ">
                        <span className="sr-only">{item.name}</span>
                        <img className="h-10 w-10" src={item.icon} aria-hidden="true" />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <Accordion.Root type="single" defaultValue={currentStep.toString()} value={currentStep.toString()} collapsible>
                <div className="flex flex-col space-y-6" ref={clientFormRef}>
                  {/* Next div positions the accordion at the right height from the parent div */}
                  <p className="font-adrianna-extended font-bold uppercase tracking-wide"></p>
                  {STEPS.map(({ placeholder, title, isDisabled, isComplete, icon, component }, i) => {
                    const isExpanded = currentStep === i;

                    const { icon: iconUrl } = getCategoryImages(category_id, service_id);

                    return (
                      <Accordion.Item key={i} value={i.toString()} disabled={isDisabled(values)}>
                        <button
                          type="button"
                          onClick={() => setCurrentStep(i)}
                          ref={refs.current[i]}
                          disabled={isDisabled(values)}
                          className={clsx(
                            "flex w-full flex-row items-center border-b border-t border-custom-black py-4 pl-2 pr-1 sm:pl-4 sm:pr-2",
                            isDisabled(values) ? "cursor-default" : "cursor-pointer"
                          )}
                        >
                          <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
                            {isComplete(values) ? (
                              <CheckIcon className="h-4 w-4 text-custom-black" />
                            ) : (
                              <p className="font-nantes font-medium text-custom-black">{i + 1}</p>
                            )}
                          </div>
                          <div className="mx-auto px-3">
                            <div className="flex items-center justify-center font-nantes text-lg tracking-wide text-custom-black">
                              {icon && iconUrl && (
                                <div className="relative mr-4 h-6 w-6 flex-shrink-0">
                                  <Image
                                    className="inset-0 h-full w-full object-contain"
                                    src={iconUrl}
                                    alt={`${title(values, category)} logo`}
                                    layout="fill"
                                  />
                                </div>
                              )}
                              <Accordion.Header>{isComplete(values) ? title(values, category) : placeholder}</Accordion.Header>
                            </div>
                          </div>
                          <div
                            className={clsx(
                              "mr-0 transform rounded-full p-1 duration-100",
                              !isDisabled(values) && !isExpanded ? "bg-secondary bg-opacity-50 group-hover:bg-opacity-80" : ""
                            )}
                          >
                            {isExpanded ? (
                              <MinusIcon className="h-5 w-5 text-custom-black opacity-50" />
                            ) : (
                              <PlusIcon className={clsx("h-5 w-5 text-custom-black", isDisabled(values) ? "opacity-50" : "")} />
                            )}
                          </div>
                        </button>
                        <Accordion.Content>{component}</Accordion.Content>
                      </Accordion.Item>
                    );
                  })}
                </div>
              </Accordion.Root>
            )}
            <Summary totalGuests={totalGuests} />
            {!done && values.bookingDates.endDate && (
              <div className="sticky bottom-5 left-0 bg-quatro md:mx-12">
                <button
                  type="submit"
                  onClick={(e) => {
                    if (currentStep === STEPS.length - 1 || methods.formState.isValid) return null;
                    e.preventDefault();
                    to(STEPS.length - 1);
                  }}
                  disabled={submitMutation.isLoading}
                  className="row-auto row-start-2 mb-0 mt-0 w-full transform bg-transparent py-4 text-center font-adrianna text-xl font-extrabold uppercase tracking-wide text-main duration-150 hover:cursor-pointer hover:bg-black hover:text-main focus:outline-none focus:ring-0 focus:ring-offset-0 active:cursor-pointer disabled:cursor-default disabled:opacity-50 disabled:hover:bg-transparent"
                >
                  {submitMutation.isLoading ? "Sending..." : "Send booking request"}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </StepContext.Provider>
  );
};

const ClientForm: React.FC = () => {
  const router = useRouter();
  const { query } = router;
  const category_id = query.category_id === undefined ? null : query.category_id;

  const methods = useForm({
    defaultValues: {
      category_id,
      service_id: null,
      bookingDates: { startDate: null, endDate: null },
      guests: {},
      contactInfo: {},
    },
  });

  return (
    <div className="h-full min-h-screen w-screen bg-main">
      <PageLayout>
        <div className="mx-auto max-w-7xl px-4 pb-2 pt-6 md:px-6 md:py-12 lg:px-8">
          <p className="text-center font-adrianna-extended text-lg font-extrabold uppercase tracking-wide sm:text-2xl md:text-3xl lg:text-4xl">
            Booking request
          </p>
        </div>
        <FormProvider {...methods}>
          <Form />
        </FormProvider>
      </PageLayout>
    </div>
  );
};

const ClientSideForm: React.FC = () => {
  const router = useRouter();

  if (!router.isReady) {
    return null;
  }

  return (
    <NoSSR>
      <ClientForm />
    </NoSSR>
  );
};

export default ClientSideForm;
